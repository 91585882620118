<template>
	<div class="wl-admin-container" style="margin-top: 70px; max-width: 1000px;">
		<span class='wl-paginate-btn' @click="$emit('close')"><i class='mdi mdi-chevron-left'></i> Back</span>
		<p class="wl-main-headline" id="collection-survey-title">{{mysurvob.survname_internal}}: {{mysurvob.statusNice}}</p>

		<div style="padding: 30px; border: 1px solid #CCC; margin-bottom: 20px; height: 450px;">
			<ApexLineTrend :chartdata="apexdata" filename="Streaming" :fetching="isFetching" :forcetocategory="true"></ApexLineTrend>
		</div>

		<div>
			<a href="javascript:void(0)" @click="$emit('getrespstatus');" class="wl-icon-btn wl-btn-border-grey"
				title="Returns status of selected Respondent ID for this survey">
				<i class='mdi mdi-account'></i>
				<span class="wl-text-left-of-fa">Status</span>
			</a>
			<router-link :to="'/dashboard/'+survid" class="wl-icon-btn wl-btn-border-grey" style="margin-left:10px;" title="View results dashboard for this survey">
					<i class='mdi mdi-gauge'></i>
					<span class="wl-text-left-of-fa">Dashboard</span>
			</router-link>

			<div style="margin: 20px 0; padding-top: 10px;">
				<p class='wl-main-headline'>Screener & Quotas</p>

				<p class='wl-p'>There are currently {{returnInf.numcompletes}} completes for this survey.
					<span v-if="mysurvob.quota">Overall survey quota is {{mysurvob.quota}}.</span>
					<span v-else>There is no overall survey quota at the top level.</span>
				</p>

				<p class='wl-p'>
					<span v-if="mysurvob.type === 'OMT'">There are currently {{returnInf.numAtSongHooks}} respondents that are at the 'song hooks' stage in this survey.</span>
					<span v-if="(mysurvob.type === 'RegRatings' && mysurvob.is_online_rr === 0) || mysurvob.type === 'CATI' || mysurvob.type === 'CATI_External'">
						This is a CATI study.  To view CATI quotas and collection info
						<router-link :to="'/cati-launch/'+survid">click here</router-link>.
					</span>
				</p>

				<p v-if="mysurvob.screener.length > 0 && screenReturn" class='wl-p'>
					The screener {{mysurvob.screener}} is active on this survey.
					<span v-if="!screenReturn.paneltest || screenReturn.paneltest.length === 0">It applies to all respondents</span>
					<span v-else>It only applies to respondents who match this criteria: {{screenReturn.paneltest}}</span>
				</p>

				<p v-if="mysurvob.status === 'closed'" class='wl-p'>Note that this survey is closed, and the Screener may have subsequently been adjusted for
					more recent waves.  Quota and Screen DQ information shown below therefore may be different
					to what was used when this survey was in live collection mode.
				</p>

				<p class='boldme wl-p'>The following are automatic disqualification criteria</p>

				<ul v-if="screenReturn && screenReturn.quotas">
					<li v-for="sl in quotaLinesAlwaysDQ" :key="sl.id">
						[{{sl.id}}] {{sl.name}}
						<span v-if="store.user.superUser === 1">({{sl.sqlfilterRaw}})</span>
					</li>
				</ul>

				<div v-if="quotaLinesHardCap.length > 0">
					<p class='boldme wl-p'>The following are hard quota caps</p>
					<ul>
						<li v-for="(ql, index) in quotaLinesHardCap" :key="index" :title="ql.notes">
							<span>
								[{{ql.id}}] {{ql.name}}
							</span>
							<span v-if="store.user.superUser === 1">
								({{ql.sqlfilterRaw}})
							</span>
							<span v-if="ql.target > 0 && ql.target.length > 0">
								Target {{ql.target}}
							</span>
							<span>Cap {{ql.quotacap}}</span>
							<span v-if="ql.numCompletesNow >= ql.quotacap" class="red">
								({{ql.numCompletesNow}} now - QUOTA FULL)
							</span>
							<span v-else>
								({{ql.numCompletesNow}} now - {{ql.quotacap - ql.numCompletesNow}} remaining until qf)
							</span>
						</li>
					</ul>
				</div>
				<div v-else><p class='boldme wl-p'>There are no other hard quota caps</p></div>

			</div>

		</div>
	</div>
</template>

<script>
import {store} from "@/store";
import {globalMixin} from "@/mixins";
import ApexLineTrend from "@/components/charts/ApexLineTrend";

export default {
	name: "CollectionScreen",
	mixins: [globalMixin],
	components: {ApexLineTrend},
	props: {
		survid: Number,
		surveys: Array,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			screenReturn: {},
			returnInf: {},
			apexdata: [], //apex colour #0f75a5, old bgcol was rgba(15,117,165,0.2)
		}
	},
	computed: {
		mysurvob() {
			return this.surveys.find(item => item.id === this.survid)
		},
		quotaLinesHardCap() {
			if(this.screenReturn.quotas) return this.screenReturn.quotas.filter(item => item.alwaysDQ === 0)
			else return [];
		},
		quotaLinesAlwaysDQ() {
			if(this.screenReturn.quotas) return this.screenReturn.quotas.filter(item => item.alwaysDQ === 1)
			else return [];
		}
	},
	methods: {
		getInfo() {
			if(this.isFetching === false) {
				this.isFetching = true;
				let targ = this.store.urlroot + "central/post-vue/home/get-collection-data";
				let sendob = {survid: this.survid};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false;
					if(ret.screen) this.screenReturn = ret.screen;
					if(ret.out) this.returnInf = ret.out;
					if(ret.apexdata) this.apexdata = ret.apexdata;
				}.bind(this));
			}
		},
	},
	watch: {
		survid() {
			this.getInfo();
		}
	},
	mounted() {
		this.getInfo();
	}
}
</script>

<style scoped>
.wl-p {
	margin-bottom: 10px;
}
ul {
	margin-bottom: 20px;
	margin-left: 20px;
	line-height: 1.2;
	list-style-type: disc;
	list-style-position: inside;
}
li {
	display: list-item;
}
.red {
	color: #F00;
}
</style>