<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<div id="maincontent" class="noTopMenuBar">
					<HookListEditorPopup v-if="hookListEditorViewShown" :stationnamelist="stationchoices"
						:onbehalfofuserid="onBehalfOfUserId" :clonefromhooklistob="clonefromhooklistob"
						v-on:close="closeHookListEditor($event)"
					></HookListEditorPopup>
					<div class="wl-admin-container">
						<p class="wl-main-headline" style="margin-bottom: 0;">Your available hook lists</p>
						<div class="altable-holder">
							<div style="display: flex; align-items: center; justify-content: flex-start; padding: 5px 0;">
								<div v-if="store.user.superUser === 1" style="margin: 5px 15px 5px 0;">
									<select class="wl-input-select" v-model="onBehalfOfUserId">
										<option v-for="us in userChoices" :key="us.id" :value="us.id">{{us.name}}</option>
									</select>
								</div>
								<button @click="createNewHooklist" type="button" class="option-button colbtn">Create New List</button>
							</div>
							<br/>
							<table class="altbl noborderv">
								<thead>
								<tr>
									<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
										<span>{{col.title}}</span>
									</td>
								</tr>
								</thead>
								<tbody>
								<tr v-for="rowitem in rowsForPage" :key="rowitem.id" :class="{boldme : rowitem.status === 'open'}">
									<td>{{rowitem.id}}: {{rowitem.nickname}}</td>
									<td>{{ucfirst(rowitem.randomise)}}</td>
									<td>{{rowitem.lastUpdate}}</td>
									<td>{{ucfirst(rowitem.status)}}</td>
									<td>{{rowitem.nHooks}}</td>
									<td><span v-if="rowitem.status !== 'complete'" class="action-icon" title="Edit Hooklist" @click.stop="editHookListById(rowitem.id)"><i class="mdi mdi-pencil"></i></span></td>
									<td><span class="action-icon" title="Clone Hooklist" @click.stop="cloneHookList(rowitem)"><i class="mdi mdi-content-copy"></i></span></td>
									<td><span class="action-icon" title="Export to Excel" @click.stop="exportHookList(rowitem)"><i class="mdi mdi-file-excel-outline"></i></span></td>
									<td><span v-if="rowitem.status !== 'complete'" class="action-icon" title="Delete Hooklist" @click.stop="deleteHookList(rowitem)"><i class='mdi mdi-trash-can-outline'></i></span></td>
									<td><span class="action-icon" title="View score history" @click.stop="viewScoreHistory(rowitem)"><i class='mdi mdi-trending-up'></i></span></td>
								</tr>
								</tbody>
							</table>
							<div style="margin: 15px 0; display: flex; align-items: center;">
								<ALTablePaginator
									:paginationpage="paginationPage"
									:paginatedrowscount="paginatedRows.length"
									v-on:nextpage="nextpage" v-on:prevpage="prevpage"
									v-on:setpage="paginationPage = $event"
								></ALTablePaginator>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import ALTablePaginator from "@/components/ALTablePaginator.vue";
import HookListEditorPopup from "@/components/hooks-admin/HookListEditorPopup.vue";

export default {
	name: "HooklistsPage",
	mixins: [globalMixin],
	components: {
		HookListEditorPopup,
		ALTablePaginator,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
			cols: [
				{title: 'Name', sortable: true, fldid: 'nickname'},
				{title: 'Randomise', sortable: true, fldid: 'randomise'},
				{title: 'Last Update', sortable: true, fldid: 'lastUpdate'},
				{title: 'Status', sortable: false, fldid: 'status'},
				{title: 'Hooks', sortable: false, fldid: 'nHooks'},
				{title: 'Edit', sortable: false, fldid: ''},
				{title: 'Clone', sortable: false, fldid: ''},
				{title: 'Export', sortable: false, fldid: ''},
				{title: 'Delete', sortable: false, fldid: ''},
				{title: 'Scores', sortable: false, fldid: ''},
			],
			//end table stuff

			hooklists: [],
			isFetching: true,
			userChoices: [],
			onBehalfOfUserId: null,
			stationchoices: [],

			chosenstationfornewlist: '',
			clonefromhooklistob: null,
			hookListEditorViewShown: false,

		}
	},
	computed: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.hooklists, this.filterText, this.sortColName, this.sortColOrder)
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
	},
	methods: {
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		getUserHooklists() {
			let targ = this.store.urlroot + "central/post-vue/hooks/get-user-lists"
			let sendob = {onBehalfOfUser: this.onBehalfOfUserId};
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.hooklists) this.hooklists = ret.hooklists
				if(ret.outUserList) {
					this.userChoices = ret.outUserList
					if(!this.onBehalfOfUserId) this.onBehalfOfUserId = this.store.user.id
				}
				if(ret.stationlist) this.stationchoices = ret.stationlist
				this.store.showLoader = false
			}.bind(this));
		},
		createNewHooklist() {
			this.chosenstationfornewlist = ''
			this.clonefromhooklistob = null
			this.hookListEditorViewShown = true
		},
		editHookListById(listid) {
			this.$router.push('/hook-select/'+listid)
		},
		cloneHookList(rowitem) {
			this.chosenstationfornewlist = rowitem.station
			this.clonefromhooklistob = rowitem
			setTimeout(function() {
				this.hookListEditorViewShown = true
			}.bind(this), 50)
		},
		exportHookList(rowitem) {
			window.location = this.store.urlroot+'central/post-vue/hooks/print-hooklist-to-excel?uid='+this.store.user.id+'&wlsesstoken='+this.store.wlsesstoken+'&listid='+rowitem.id
		},
		deleteHookList(rowitem) {
			let c = confirm("Are you sure you want to delete this hooklist?")
			if(c === true) {
				let targ = this.store.urlroot + "central/post-vue/hooks/delete-hooklist"
				let sendob = {deletelist: rowitem.id, onBehalfOfUser: this.onBehalfOfUserId}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					if(ret.hooklists) this.hooklists = ret.hooklists
				}.bind(this));
			}
		},
		viewScoreHistory(rowitem) {
			this.$router.push('/reports?hooklist='+rowitem.id+'&stat=popindex&demo=0')
			//window.location = this.store.urlroot + "central/reports?hooklist="+rowitem.id+"&stat=popindex&demo=0"
		},
		closeHookListEditor(editListId) {
			this.hookListEditorViewShown = false
			if(editListId > 0) {
				this.editHookListById(editListId)
			}
		},
	},
	watch: {
		'store.sessionOk'() {
			if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.hookcentral !== 1) {
				this.$router.push('/error/no-access');
			}
		},
		rows() { //whenever filter text or sort is changed, go back to Page 1 in pagination
			this.paginationPage = 1
		},
		onBehalfOfUserId() {
			this.getUserHooklists()
		},
	},
	mounted() {
		if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.hookcentral !== 1) {
			this.$router.push('/error/no-access');
		}
		this.getUserHooklists()
	},
	created() {
		document.title = "Wavelength Hooks Lists";
		this.store.activeLmenuLink = 'hooks';
	}
}
</script>

<style scoped>

</style>